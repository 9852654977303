<template>
  <div>
    <filter-bar
      :mode_form="mode_form"
      @filter_data="filter"
      @isLoading="isLoading"
      :selected_page="selected_page"
      :per_page="per_page"
    >
    </filter-bar>

    <b-card>
      <filter-table :filter_data="filter_data" :is_loading="is_loading">
      </filter-table>
      <order-pagination
        :total_rows="total_rows"
        :per_page="per_page"
        @pageChange="
          (x) => {
            this.selected_page = x;
          }
        "
      >
      </order-pagination>
    </b-card>
  </div>
</template>

<script>
import { BCard, BOverlay } from "bootstrap-vue";
import FilterBar from "./component/FilteredBar.vue";
import FilterTable from "./component/FilterTable.vue";
import OrderPagination from "../order/component/OrderPagination.vue";

export default {
  components: {
    BCard,
    FilterBar,
    FilterTable,
    OrderPagination,
    BOverlay,
  },
  data() {
    return {
      per_page: 50,
      selected_page: 1,
      total_rows: 0,
      filter_data: [],
      mode_form: {
        side: "pickup",
        focus_time: "all",
        method: "all",
      },
      is_loading: true,
    };
  },
  created() {},
  methods: {
    filter(x) {
      this.filter_data = x.filter_data;
      this.total_rows =
        this.filter_data.length > 0 ? this.filter_data[0].total_count : 0;
    },
    isLoading(x) {
      this.is_loading = x;
    },
  },
};
</script>

<style></style>
