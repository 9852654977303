<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6" v-if="form.side != 'return'">
          <b-form-group label="การรับอุปกรณ์">
            <b-form-radio-group
              @change="filter"
              v-model="form.method"
              :options="method_options"
              name="radios-stacked3"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <div v-if="form.method == 'messenger'">
            <b-row>
              <b-col>
                <b-form-group label="รอบจัดส่ง">
                  <b-form-radio-group
                    @change="filter"
                    v-model="form.focus_time"
                    :options="get_messenger_round_options"
                    name="radios-stacked14"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="form.method == 'self'">
            <b-row>
              <b-col v-if="form.side == 'pickup'">
                <b-form-group label="รอบรับ">
                  <b-form-radio-group
                    @change="filter"
                    v-model="form.focus_time"
                    :options="get_self_round_options"
                    name="radios-stacked"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="form.side == 'return'">
            <b-row>
              <b-col>
                <b-form-group label="รอบคืน">
                  <b-form-radio-group
                    @change="filter"
                    v-model="form.focus_time"
                    :options="return_self_round_options"
                    name="radios-stacked"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col md="6">
          <!-- {{ form.side }} -->
          <!-- {{ form.is_get }} -->
          <b-form-group label="สถานะการรับอุปกรณ์" v-if="form.side == 'pickup'">
            <b-form-radio-group
              @change="filter"
              v-model="form.is_get"
              :options="is_get_options"
              name="radios-stacked2"
            />
          </b-form-group>

          <b-form-group label="สถานะการคืนอุปกรณ์" v-if="form.side == 'return'">
            <b-form-radio-group
              @change="filter"
              v-model="form.is_return"
              :options="is_return_options"
              name="radios-stacked2"
            />
          </b-form-group>
          <b-form-group label="การชำระเงิน" v-if="form.side != 'return'">
            <b-form-radio-group
              @change="filter"
              v-model="form.payment"
              :options="payment_options"
              name="radios-stacked4"
            />
          </b-form-group>

          <b-form-group label="สถานะ order" v-if="form.side != 'return'">
            <b-form-radio-group
              @change="filter"
              v-model="form.order_status"
              :options="order_status_options"
              name="radios-stacked5"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <div class="row">
            <div class="col">
              <b-form-group>
                <date-picker @date_picked="date_picked" />
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- sumRental: {{ sumRental | number }} <br />
      sumShipping: {{ sumShipping | number }} -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DatePicker from "../../components/Datepicker.vue";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    BCol,
    DatePicker,
    BFormInput,
    BButton,
  },
  watch: {
    selected_page(newPage, oldPage) {
      this.form.selected_page = newPage;
      this.filter();
    },
  },
  data() {
    return {
      filter_data: [],
      form: {
        selected_page: 1,
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      method_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "รับหน้าร้าน", value: "self" },
        { text: "ส่ง Messenger", value: "messenger" },
        { text: "ส่ง Kerry", value: "kerry" },
      ],
      side_options: [
        { text: "รับ", value: "pickup" },
        { text: "คืน", value: "return" },
      ],
      get_messenger_round_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "รอบที่ 1 | 13:00-16:00", value: "11:30:00" },
        { text: "รอบที่ 2 | 16:00-18:00", value: "14:00:00" },
      ],
      get_self_round_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "11:30", value: "11:30:00" },
        { text: "14:00", value: "14:00:00" },
        { text: "17:00", value: "17:00:00" },
      ],
      return_self_round_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "13:00", value: "13:00:00" },
        { text: "16:00", value: "16:00:00" },
        { text: "19:00", value: "19:00:00" },
      ],
      is_get_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "ยังไม่ได้รับ", value: "not_receive" },
        { text: "รับแล้ว", value: "received" },
      ],
      is_return_options: [
        { text: "ทั้งหมด", value: "all" },
        { text: "คืนแล้ว", value: "returned" },
        { text: "ยังไม่ได้คืน", value: "not_returned" },
      ],
      payment_options: [
        { text: "ทั้งหมด", value: "all" },
        // { text: "ยังไม่ได้ชำระเงิน", value: "payment-complete" },
        // { text: "ชำระแล้ว", value: "payment-un-complete" },
        { text: "ยังไม่ได้ชำระเงิน", value: "false" },
        { text: "ชำระแล้ว", value: "true" },
      ],
      order_status_options: [
        { text: "ปกติ", value: "complete" },
        { text: "ไม่มารับของ", value: "no-show" },
      ],
    };
  },
  computed: {
    sumRental() {
      return this.filter_data.reduce(
        (total, payment) => total + payment.rental_price,
        0
      );
    },
    sumShipping() {
      return this.filter_data.reduce(
        (total, payment) => total + payment.shipping_price,
        0
      );
    },
  },
  created() {
    this.$emit("isLoading", true);
    if ($cookies.get("order-" + this.mode_form.side + "-options")) {
      this.form = $cookies.get("order-" + this.mode_form.side + "-options");
    } else {
      this.form = {
        focus_date: moment().format("YYYY-MM-DD"),
        focus_time: "all",
        method: "self",
        side: this.mode_form.side, /// รับ หรือ คืน
        is_get: "all",
        payment: "all",
        is_return: "all",
        order_status: "complete",
      };
    }
    this.addCount();
    this.filter();
  },
  props: ["mode_form", "per_page", "selected_page"],
  methods: {
    mapOptionsWithCounts(options, values) {
      // Create a counts map for quick lookup
      const countsMap = values.reduce((map, item) => {
        map[item._id] = item.count;
        return map;
      }, {});
      const totalCount = values.reduce((sum, item) => sum + item.count, 0);
      const not_return_count =
        values.find((item) => item._id === "returned")?.count || 0;
      return options.map((option) => {
        if (option.value === "all") {
          const new_text = option.text.replace(/\(.*\)/, "");
          return { ...option, text: `${new_text} (${totalCount})` };
        } else if (option.value === "not_returned") {
          const new_text = option.text.replace(/\(.*\)/, "");
          const count = countsMap[option.value] || 0;
          return {
            ...option,
            text: `${new_text} (${totalCount * 1 - not_return_count})`,
          };
        } else {
          const count = countsMap[option.value] || 0;
          const new_text = option.text.replace(/\(.*\)/, "");

          return { ...option, text: `${new_text} (${count})` };
        }
      });

      // return options.map((option) => {
      //   if (option.value === "all") {
      //     console.log(option, "..option");
      //     return { ...option, text: `${option.text} 0(${totalCount})` };
      //   } else if (option.value === "not_returned") {
      //     const count = countsMap[option.value] || 0;
      //     return {
      //       ...option,
      //       text: `${option.text} a(${totalCount * 1 - not_return_count})`,
      //     };
      //   } else {
      //     const count = countsMap[option.value] || 0;
      //     return { ...option, text: `${option.text} b(${count})` };
      //   }
      // });
    },

    addCount() {
      const status_count = {};
      console.log("this.form", this.form);
      this.$http({
        method: "POST",
        url: `/order/list/count`,
        data: this.form,
      }).then(async (x) => {
        const values = x.data.data;

        /// วิธีรับของ
        this.method_options = this.mapOptionsWithCounts(
          this.method_options,
          values.pickup_method
        );

        /// การชำระเงิน
        this.payment_options = this.mapOptionsWithCounts(
          this.payment_options,
          values.is_paid
        );

        if (this.form.side === "pickup") {
          /// รับเองแต่ละเวลา
          let self_start_date = values.start_date;
          self_start_date = self_start_date
            .filter((result) => result.pickup_method === "self")
            .map((result) => ({
              ...result,
              _id: result.start_date.substring(11, 19),
            }));
          this.get_self_round_options = this.mapOptionsWithCounts(
            this.get_self_round_options,
            self_start_date
          );
          /// ส่งแมสแต่ละเวลา
          let messenger_start_date = values.start_date;
          messenger_start_date = messenger_start_date
            .filter((result) => result.pickup_method === "messenger")
            .map((result) => ({
              ...result,
              _id: result.start_date.substring(11, 19),
            }));

          this.get_messenger_round_options = this.mapOptionsWithCounts(
            this.get_messenger_round_options,
            messenger_start_date
          );
          // สถานะรับของ pickup_status
          this.is_get_options = this.mapOptionsWithCounts(
            this.is_get_options,
            values.pickup_status
          );
        }

        if (this.form.side === "return") {
          /// คืนแต่ละเวลา
          let self_end_date = values.end_date;
          self_end_date = self_end_date
            .filter((result) => result.pickup_method === "self")
            .map((result) => ({
              ...result,
              _id: result.end_date.substring(11, 19),
            }));
          this.return_self_round_options = this.mapOptionsWithCounts(
            this.return_self_round_options,
            self_end_date
          );

          // สถานะคืนของ pickup_status

          this.is_return_options = this.mapOptionsWithCounts(
            this.is_return_options,
            values.pickup_status
          );
        }
      });

      // const dynamicValuesFromBackend = ["value1", "value2", "value3", "value4"];
    },
    selectdateEvent() {
      console.log(this.selected_date);
    },
    date_picked(x) {
      this.form.focus_date = x;
      this.form.focus_date_start = x;
      this.form.focus_date_end = x;

      this.filter();
      this.addCount();
    },
    async filter() {
      this.$emit("isLoading", true);
      this.form.selected_page = this.selected_page;
      this.form.per_page = this.per_page;
      if (this.form.method == "all" && this.form.side == "pickup") {
        this.form.focus_time = "all";
      }
      if (!this.form.focus_date_start) {
        this.form.focus_date_start = moment().format("YYYY-MM-DD");
      }
      if (!this.form.focus_date_end) {
        this.form.focus_date_end = moment().format("YYYY-MM-DD");
      }

      if (this.form.side == "return") {
        this.form.method = "all";
      }

      const { focus_date, focus_date_start, focus_date_end, ...cook } =
        this.form;

      await $cookies.set("order-" + this.mode_form.side + "-options", cook);

      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
        this.$emit("filter_data", {
          filter_data: x.data.data,
          form_data: this.form,
        });
        this.$emit("isLoading", false);
      });
    },
    // moment.tz(this.form.start_date + " " + this.form.start_time,"Asia/Bangkok")
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style></style>
