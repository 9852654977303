<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0 mt-1">
        Showing {{ (selected_page - 1) * per_page + 1 }} of {{ total_rows }}
      </div>
      <div>
        <b-pagination
          :value="selected_page"
          :total-rows="total_rows"
          :per-page="per_page"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="handlePageChange"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  components: {
    BPagination,
  },
  props: ["total_rows", "per_page"],
  created() {},
  data() {
    return { selected_page: 1 };
  },
  methods: {
    handlePageChange(x) {
      this.selected_page = x;
      this.$emit("pageChange", x);
    },
  },
};
</script>
