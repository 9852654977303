<template>
  <div>
    <b-form-group label="วันที่">
      <date-picker
        v-model="form"
        :masks="{ input: 'D/M/YYYY' }"
        :model-config="modelConfig"
        mode="date"
        @input="datepicked"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <b-form-group>
            <!-- :mode="date-time" -->
            <b-form-input
              class="border px-2 py-1 rounded"
              :value="inputValue"
              @click="togglePopover()"
              autocomplete="off"
            />
          </b-form-group>
        </template>
      </date-picker>
      <!-- <b-button size="sm" variant="info" @click="setToday()"> Today </b-button> -->
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    DatePicker,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      form: moment().format("YYYY-MM-DD"), ///moment().format("YYYY-MM-DD"),
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  created() {
    const pathname = this.$route.path;
    const parts = pathname.split("/");
    const lastPart = parts[parts.length - 1];
    const cookie_date = $cookies.get(
      "order-" + lastPart + "-options"
    ).focus_date;

    if (cookie_date) {
      this.$emit("date_picked", cookie_date);
      this.form = cookie_date;
    } else {
      this.datepicked();
    }
  },
  methods: {
    setToday() {
      const today = new Date();
      // Extract year, month, and day
      const year = today.getFullYear();
      const month = ("0" + (today.getMonth() + 1)).slice(-2); // Months are zero based
      const day = ("0" + today.getDate()).slice(-2);
      this.form = `${year}-${month}-${day}`;
      this.$emit("date_picked", this.form);
    },
    datepicked() {
      //   if (cookie) {
      //     console.log('ga')
      //     this.$emit("date_picked", cookie);

      //   } else {
      this.$emit("date_picked", this.form);
      //   }
    },
  },
};
</script>
